import React from "react";
import { Link } from "gatsby";
const Modal = ({isOpen}) => {
    return (
        <>
            {isOpen ? (
                <div className={"w-full  align-middle justify-center  bg-tema4 text-white"}>
                    <div className={""}>
                        <ul className={"flex flex-col py-1"}>
                            <li className={"p-2 text-white"}>
                                Acerca de
                                <ul className={" flex-col "}>
                                    <li className="px-4 py-3 border-2">
                                        <Link to={"/acerca/que-es"}>
                                            ¿Qué es QuEST?
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/acerca/objetivos"}>
                                            Objetivos
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/acerca/estructura"}>
                                            Estructura
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/acerca/financiamiento"}>
                                            Financiamiento
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={"  p-2"}>
                                Calidad en salud
                                <ul className={" flex-col "}>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/calidadensalud/definiciones"}>
                                            Definiciones
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/calidadensalud/importancia"}>
                                            Importancia en LAC
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={"  p-2"}>
                                Proyectos
                                <ul className={" flex-col "}>
                                <li className="px-4 py-3 border-2 ">
                                        <Link to={"/proyectos/vozgente"}>
                                           La voz de la gente
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/proyectos/ecohorts"}>
                                            Cohortes electrónicas
                                        </Link>
                                    </li>
                                  
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/proyectos/futuros"}>
                                            Futuros
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={"  p-2"}>
                                Actividades
                                <ul className={" flex-col "}>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/actividades/webinars"}>
                                            Webinars
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/actividades/cursos-cortos"}>
                                            Cursos Cortos
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/actividades/publicaciones"}>
                                            Publicaciones
                                        </Link>
                                    </li>
                                    <li className="px-4 py-3 border-2 ">
                                        <Link to={"/actividades/convocatorias"}>
                                            Convocatorias
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={"p-2 border-2"}>
                            <Link to={"/contacto"}>Contacto</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Modal;